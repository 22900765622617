<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.employees')">
            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.GeneralWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon" />
                    {{ $t("keys.employees.add_employee") }}
                </b-button>
            </div>
            <Table
                v-if="arraysLoad"
                class="mt-1"
                :columnDefs="columnDefs"
                :rowData="employees"
                @deleteRow="remove"
                @editRow="editRow"
                @edit="edit"
            />
        </b-card>

        <AddEmployee
            ref="addModal"
            :employeePermissionGroups="employeePermissionGroups"
            v-on:itemAdded="loadData"
        />
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddEmployee from '@/views/Employee/AddEmployee'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import {validatorTAX} from '@core/utils/validations/validators'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddEmployee, BCard, BOverlay, BButton},
        computed: {
            columnDefs() {
                const hasPermission = this.$hasPermission(this.$permissions.GeneralWrite)

                const defs = [
                    { headerName: this.$t('table_fields.person_name'), field: 'name', filter: true, editable: hasPermission},
                    { headerName: this.$t('table_fields.print_name'), field: 'print_name', filter: true, editable: hasPermission},
                    { headerName: this.$t('table_fields.tax_number'), field: 'tax_number', editable: hasPermission, filter: true, cellStyle: params => { return params.data.is_employee_foreign ? {'pointer-events': 'none', 'opacity': '0.4' } : '' }},
                    { headerName: this.$t('table_fields.pin'), field: 'access_pin', filter: true, editable: hasPermission},
                    { headerName: this.$t('table_fields.permissions'), field: 'employee_permission_group_id', editable: hasPermission, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.employeePermissionGroups} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.employeePermissionGroups} }, cellRenderer: (params) => this.getPermissonGroupName(params.value)},
                    { headerName: this.$t('table_fields.foreign_employee'), field: 'is_employee_foreign', editable: hasPermission, cellRenderer: 'DisplayActive', cellEditorFramework: 'activeEditor',  filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'} }
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defs
            }

        },
        data() {
            return {
                employees: [],
                employeePermissionGroups: [],
                showLoader: false,
                arraysLoad: false,
                foreignWorker: [
                    {
                        id: 0,
                        name: 'Da',
                        value: true
                    },
                    {
                        id: 1,
                        name: 'Ne',
                        value: false
                    }
                ]
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/employees/')
                    const response2 = this.$http.get('/api/client/v1/employee_permission_groups/')
        
                    const responses = await Promise.all([response1, response2])
                    this.employees = responses[0].data ?? []
                    this.employeePermissionGroups = responses[1].data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true
                    if (params.data.is_employee_foreign) {
                        params.data.tax_number = ''
                    } else if (!validatorTAX(params.data.tax_number)) {
                        this.$printError(this.$t('print.error.invalid_vat_number'))
                        return
                    }

                    const editObject = {object_data: params.data}
                    await this.$http.patch(`/api/client/v1/employees/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printSuccess(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printSuccess(this.$t('print.warning.tax_number_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/employees/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            getPermissonGroupName(value) {
                if (value) {
                    const item = this.employeePermissionGroups.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
